import React, { useState, useEffect } from "react";
import axios from "axios";

const Work = () => {
  const [formData, setFormData] = useState({
    client_name: "",
    country: "",
    date: "",
    category: "",
    project_name: "",
    cover: "",
    image_one: "",
    image_two: "",
    image_three: "",
    link: "",
    description: "",
    technology: "",
  });

  const [aboutData, setAboutData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState(null);
  const [showForm, setShowForm] = useState(false); // New state for form visibility

  useEffect(() => {
    fetchAboutData();
  }, []);

  const fetchAboutData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_BACK}/api/work`
      );
      setAboutData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataToSend = new FormData();
    for (const key in formData) {
      if (formData[key] instanceof File) {
        dataToSend.append(key, formData[key]);
      } else {
        dataToSend.append(key, formData[key]);
      }
    }
    try {
      if (editMode) {
        await axios.put(
          `${process.env.REACT_APP_API_URL_BACK}/api/work/${editId}`,
          dataToSend
        );
      } else {
        await axios.post(
          `${process.env.REACT_APP_API_URL_BACK}/api/work`,
          dataToSend
        );
      }
      setFormData({
        client_name: "",
        country: "",
        date: "",
        category: "",
        project_name: "",
        cover: "",
        image_one: "",
        image_two: "",
        image_three: "",
        link: "",
        description: "",
        technology: "",
      });
      setEditMode(false);
      setShowForm(false); // Hide form after submission
      fetchAboutData();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleEdit = (data) => {
    setFormData({
      client_name: data.client_name,
      country: data.country,
      date: data.date,
      category: data.category,
      project_name: data.project_name,
      cover: data.cover,
      image_one: data.image_one,
      image_two: data.image_two,
      image_three: data.image_three,
      link: data.link,
      description: data.description,
      technology: data.technology,
    });
    setEditId(data._id);
    setEditMode(true);
    setShowForm(true); // Show form in edit mode
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL_BACK}/api/Work/${id}`
      );
      fetchAboutData();
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  return (
    <div className="container mt-5">
      <h2>Work Section</h2>
      <button
        className="btn btn-primary mb-3"
        onClick={() => {
          setShowForm(!showForm); // Toggle form visibility
          setEditMode(false); // Reset edit mode when adding new data
        }}>
        {showForm ? "Cancel" : "Add Data"}
      </button>
      {showForm && (
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="client_name">Client Name</label>
              <input
                type="text"
                className="form-control"
                id="client_name"
                name="client_name"
                value={formData.client_name}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group col-md-3">
              <label htmlFor="country">Country</label>
              <input
                type="text"
                className="form-control"
                id="country"
                name="country"
                value={formData.country}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group col-md-3">
              <label htmlFor="date">Date</label>
              <input
                type="text"
                className="form-control"
                id="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="category">Category</label>
              <input
                type="text"
                className="form-control"
                id="category"
                name="category"
                value={formData.category}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group col-md-9">
              <label htmlFor="project_name">Project Name</label>
              <input
                type="text"
                className="form-control"
                id="project_name"
                name="project_name"
                value={formData.project_name}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="link">Web Link</label>
              <input
                type="text"
                className="form-control"
                id="link"
                name="link"
                value={formData.link}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group col-md-9">
              <label htmlFor="technology">Technologies Used</label>
              <input
                type="text"
                className="form-control"
                id="technology"
                name="technology"
                value={formData.technology}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-12">
              <label htmlFor="description">Detail about Project</label>
              <input
                type="text"
                className="form-control"
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="cover">Cover Image</label>
              <input
                type="file"
                className="form-control"
                id="cover"
                name="cover"
                onChange={handleFileChange}
              />
              <img
                src={
                  formData.cover
                    ? `${process.env.REACT_APP_API_URL_BACK}/${formData.cover}`
                    : "default-image-path.jpg"
                }
                alt="Banner"
                style={{ width: "150px", height: "50px", objectFit: "cover" }}
              />
            </div>

            <div className="form-group col-md-3">
              <label htmlFor="image_one">First Image</label>
              <input
                type="file"
                className="form-control"
                id="image_one"
                name="image_one"
                onChange={handleFileChange}
              />
              <img
                src={
                  formData.image_one
                    ? `${process.env.REACT_APP_API_URL_BACK}/${formData.image_one}`
                    : "default-image-path.jpg"
                }
                alt="Banner"
                style={{ width: "150px", height: "50px", objectFit: "cover" }}
              />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="image_two">Second Image </label>
              <input
                type="file"
                className="form-control"
                id="image_two"
                name="image_two"
                onChange={handleFileChange}
              />
              <img
                src={
                  formData.image_two
                    ? `${process.env.REACT_APP_API_URL_BACK}/${formData.image_two}`
                    : "default-image-path.jpg"
                }
                alt="Banner"
                style={{ width: "150px", height: "50px", objectFit: "cover" }}
              />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="image_three">Third Image</label>
              <input
                type="file"
                className="form-control"
                id="image_three"
                name="image_three"
                onChange={handleFileChange}
              />
              <img
                src={
                  formData.image_three
                    ? `${process.env.REACT_APP_API_URL_BACK}/${formData.image_three}`
                    : "default-image-path.jpg"
                }
                alt="Banner"
                style={{ width: "150px", height: "50px", objectFit: "cover" }}
              />
            </div>
          </div>
          <button type="submit" className="btn btn-primary">
            {editMode ? "Update" : "Add"}
          </button>
        </form>
      )}
      <table className="table">
        <thead>
          <tr>
            <th>Cover</th>
            <th>Client Name</th>
            <th>Date</th>
            <th>Category</th>
            <th>Project Name</th>
            <th>Web Link</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {aboutData.map((data) => (
            <tr key={data._id}>
              <td>
                <img
                  src={`${process.env.REACT_APP_API_URL_BACK}/${data.cover}`}
                  alt="Cover"
                  width="100"
                />
              </td>
              <td>{data.client_name}</td>
              <td>{data.date}</td>
              <td>{data.category}</td>
              <td>{data.project_name}</td>
              <td>{data.link}</td>
              <td>
                <button onClick={() => handleEdit(data)}>
                  <i className="fas fa-pen"></i>
                </button>
                <button
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to delete this item?"
                      )
                    ) {
                      handleDelete(data._id);
                    }
                  }}>
                  <i className="fas fa-trash"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Work;
