import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import thumb2 from "../assets/img/about/4.jpg";
import shape1 from "../assets/img/shape/1.svg";
import thumb3 from "../assets/img/about/3.jpg";
import banner from "../assets/img/banner/7.jpg";

import shapeImage5 from "../assets/img/shape/5.png";
import shapeImage2 from "../assets/img/shape/2.svg";
const Home = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL_BACK}/api/homepage`
        );
        const result = await res.json();
        setData(result);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch homepage data", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }
  return (
    <>
      {/*Start Banner */}
      <div className="banner-area auto-height text-light bg-gradient mar-top-100p">
        <div className="item">
          <div className="container">
            {data.sliders.map((slider, index) => (
              <div key={index} className="row align-center">
                <div className="col-lg-6">
                  <div className="content">
                    <h2 className="wow fadeInDown">
                      <strong>{slider.title}</strong>
                    </h2>
                    <p className="wow fadeInLeft">{slider.subtitle}</p>
                    <Link
                      className="btn circle btn-light effect btn-md wow fadeInUp"
                      to="/services">
                      {slider.button_name}{" "}
                      <i className="fas fa-arrow-alt-circle-right"></i>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6 thumb">
                  <img
                    className="wow fadeInUp"
                    src={slider.image}
                    alt="Thumb"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          className="shape"
          style={{ backgroundImage: `url(${shapeImage5})` }}></div>
      </div>
      {/*End Banner */}

      {/* Start About Area */}
      <div className="about-content-area bg-gray default-padding-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="thumb">
                <img src={thumb2} alt="Thumb" />
                <div className="overlay">
                  <div className="icon">
                    <i className="flaticon-award-star-with-olive-branches"></i>
                  </div>
                  <div className="content">
                    <h4>Founded in 2018</h4>
                    <p>That shaped what we are today!</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 offset-lg-1 info">
              <h2>
                Enhancing Efficiency and Performance with Next-Gen IT Solutions
              </h2>
              <p style={{ textAlign: "justify" }}>
                We are committed to enhancing your business’s efficiency and
                performance through next-generation IT solutions tailored to
                your needs. By leveraging advanced technologies such as
                artificial intelligence, cloud computing, and automation, we
                provide tailored solutions that address the unique challenges
                faced by each client.
              </p>
              <div className="content-tabs">
                <ul id="tabs" className="nav nav-tabs">
                  <li className="nav-item">
                    <a
                      href="#tab1"
                      data-toggle="tab"
                      className="active nav-link">
                      Our Mission
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#tab2" data-toggle="tab" className="nav-link">
                      Our Vision
                    </a>
                  </li>
                </ul>
                <div
                  id="tabsContent"
                  className="tab-content wow fadeInUp"
                  data-wow-delay="0.5s">
                  <div id="tab1" className="tab-pane fade active show">
                    <p style={{ textAlign: "justify" }}>
                      To be the leading provider of innovative IT solutions,
                      empowering businesses with cutting-edge technology to
                      drive digital transformation and achieve sustainable
                      growth.
                    </p>
                  </div>
                  <div id="tab2" className="tab-pane fade">
                    <p style={{ textAlign: "justify" }}>
                      To deliver exceptional software and services that exceed
                      client expectations through continuous innovation,
                      personalized support, and a commitment to excellence. We
                      strive to enhance operational efficiency and foster
                      success for our customers in an ever-evolving digital
                      landscape.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area */}

      {/* Start Features Area */}
      <div className="top-features-area default-padding-top bg-gray bottom-less">
        <div className="container">
          <div className="feature-items text-center">
            <div className="row">
              {/* Single Item */}
              <div className="single-item col-lg-3 col-md-6">
                <div className="item">
                  <div className="icon">
                    <i className="flaticon-cogwheel"></i>
                  </div>
                  <h5>
                    <a href="#">IT Consultancy</a>
                  </h5>
                  <p>
                    Expert IT consultancy to optimize technology strategies,
                    enhance efficiency, and drive business growth.
                  </p>
                </div>
              </div>
              {/* End Single Item */}
              {/* Single Item */}
              <div className="single-item col-lg-3 col-md-6">
                <div className="item">
                  <div className="icon">
                    <i className="flaticon-cloud-storage"></i>
                  </div>
                  <h5>
                    <a href="#">Cloud Computing</a>
                  </h5>
                  <p>
                    Robust cloud solutions for seamless data access, management,
                    scalability, and flexibility in operations.
                  </p>
                </div>
              </div>
              {/* End Single Item */}
              {/* Single Item */}
              <div className="single-item col-lg-3 col-md-6">
                <div className="item">
                  <div className="icon">
                    <i className="flaticon-globe-grid"></i>
                  </div>
                  <h5>
                    <a href="#">Cyber Security</a>
                  </h5>
                  <p>
                    Comprehensive cyber security measures to protect data and
                    systems from threats, ensuring business safety.
                  </p>
                </div>
              </div>
              {/* End Single Item */}
              {/* Single Item */}
              <div className="single-item col-lg-3 col-md-6">
                <div className="item">
                  <div className="icon">
                    <i className="flaticon-backup"></i>
                  </div>
                  <h5>
                    <a href="#">Backup & Recovery</a>
                  </h5>
                  <p>
                    Reliable backup and recovery solutions to protect data and
                    maintain business continuity during disruptions.
                  </p>
                </div>
              </div>
              {/* End Single Item */}
            </div>
          </div>
        </div>
        {/* Fixed Shape Bottom */}
        <div className="fixed-shape-bottom">
          <img src={shapeImage2} alt="Shape" />
        </div>
        {/* End Fixed Shape Bottom */}
      </div>
      {/* End Features Area */}

      {/* Start Works About */}
      <div className="works-about-area bg-light overflow-hidden">
        <div className="container">
          <div className="works-about-items default-padding">
            {data.abc.map((abc, index) => (
              <div key={index} className="row align-center">
                <div className="col-lg-6 info">
                  <h5>Works About</h5>
                  <h2>{abc.s_title}</h2>
                  <p style={{ textAlign: "justify" }}>{abc.s_description}</p>
                  <ul>
                    <li>
                      <h5>100% Client Satisfaction</h5>
                    </li>
                    <li>
                      <h5>World Class Worker</h5>
                    </li>
                  </ul>
                  <Link className="btn btn-theme effect btn-sm" to="/contactus">
                    Talk to a consultant
                  </Link>
                </div>
                <div className="col-lg-6">
                  <div className="thumb">
                    <img src={thumb3} alt="Thumb" />
                    <div className="fun-fact">
                      <div className="timer" data-to="875" data-speed="5000">
                        {abc.completed_projects}
                      </div>
                      <span className="medium">Completed Projects</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* End Works About Area */}

      {/* Start Services */}
      <div className="thumb-services-area carousel-shadow relative bg-cover">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="site-heading text-center">
                <h4>Services</h4>
                <h2>What we do</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="services-items services-carousel owl-carousel owl-theme text-center owl-loaded owl-drag">
            {/* Single Item */}
            <div className="owl-stage-outer">
              <div
                className="owl-stage"
                style={{
                  transform: "translate3d(-760px, 0px, 0px)",
                  transition: "0.25s",
                  width: "1900px",
                }}>
                {data.services.slice(0, 5).map((service, index) => (
                  <div
                    key={index}
                    className="owl-item active"
                    style={{
                      width: "350px",
                      marginRight: "30px",
                      marginBottom: "30px",
                    }}>
                    <div className="item">
                      <div className="info">
                        <h4>{service.sname}</h4>

                        <i
                          className={service.icon}
                          style={{
                            fontSize: "70px",
                          }}></i>

                        <p style={{ marginTop: "30px" }}>{service.sdetails}</p>
                        <Link
                          className="btn-standard"
                          to={`/service/${service._id}`}>
                          Discover More<i className="fas fa-angle-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}

                <Link className="btn btn-theme effect btn-md" to="/services">
                  View All Services
                </Link>

                {/* End Single Item */}
              </div>
            </div>
            {/* Navigation */}
            <div className="owl-nav disabled">
              <div className="owl-prev">
                <i className="fa fa-angle-left"></i>
              </div>
              <div className="owl-next">
                <i className="fa fa-angle-right"></i>
              </div>
            </div>
            {/* Dots */}
            <div className="owl-dots">
              <div className="owl-dot">
                <span></span>
              </div>
              <div className="owl-dot active">
                <span></span>
              </div>
            </div>
          </div>
        </div>
        {/* Fixed Shape Bottom */}
        <div className="fixed-shape-bottom">
          <img src={shape1} alt="Shape" />
        </div>
        {/* Fixed Shape Bottom */}
      </div>
      {/* End Services Area */}

      {/* Start Quick Contact Area */}
      <div className="quick-contact-area half-bg default-padding-top">
        <div className="container">
          <div
            className="quick-contact-items shadow dark bg-cover text-light"
            style={{ backgroundImage: `url(${banner})` }}>
            <div className="row align-center">
              <div className="col-lg-8">
                <h5>Need help?</h5>
                <h2>
                  Easy solutions for all <strong>difficult IT problems</strong>,
                  keep business safe & ensure high availability.
                </h2>
              </div>
              <div className="col-lg-4 text-right">
                <Link className="btn btn-light effect btn-md" to="/contact">
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Quick Contact Area */}

      {/* Start Faq Area */}
      <div className="faq-area default-padding bg-gray">
        <div className="container">
          <div className="faq-items">
            <div className="row">
              <div className="col-lg-5 info">
                <h5>FAQ</h5>
                <h2>Most common questions about our services</h2>
                <Link className="btn btn-theme effect btn-md" to="/about">
                  View All
                </Link>
              </div>
              <div className="col-lg-7">
                <div className="faq-content">
                  {data.abc.map((abc, index) => (
                    <div
                      key={index}
                      className="accordion"
                      id="accordionExample">
                      <div className="card">
                        <div className="card-header" id="headingOne">
                          <h4
                            className="mb-0"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne">
                            <strong>?</strong> {abc.faq_one}
                          </h4>
                        </div>
                        <div
                          id="collapseOne"
                          className="collapse show"
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample">
                          <div className="card-body">
                            <p>{abc.faq_one_ans}</p>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingTwo">
                          <h4
                            className="mb-0 collapsed"
                            data-toggle="collapse"
                            data-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo">
                            <strong>?</strong> {abc.faq_two}
                          </h4>
                        </div>
                        <div
                          id="collapseTwo"
                          className="collapse"
                          aria-labelledby="headingTwo"
                          data-parent="#accordionExample">
                          <div className="card-body">
                            <p>{abc.faq_two_ans}</p>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingFour">
                          <h4
                            className="mb-0 collapsed"
                            data-toggle="collapse"
                            data-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour">
                            <strong>?</strong> {abc.faq_three}
                          </h4>
                        </div>
                        <div
                          id="collapseFour"
                          className="collapse"
                          aria-labelledby="headingFour"
                          data-parent="#accordionExample">
                          <div className="card-body">
                            <p>{abc.faq_three_ans}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Faq Area */}

      {/* Start Blog Area */}
      <div className="blog-area content-less default-padding bottom-less">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="site-heading text-center">
                <h4>Popular News</h4>
                <h2>Latest From our Blog</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="blog-items">
            <div className="row">
              {/* Single Item */}
              {data.blogs.slice(0, 5).map((blog, index) => (
                <div className="single-item col-lg-4 col-md-6">
                  <div className="item">
                    <div className="thumb">
                      <Link to={`/blog/${blog._id}`}>
                        {" "}
                        <img src={blog.image} alt="Blog Thumbnail 1" />
                      </Link>
                    </div>
                    <div className="info">
                      <div className="cats">
                        <a href="#">{blog.category}</a>
                      </div>
                      <div className="meta">
                        <ul>
                          <li>
                            <i className="fas fa-calendar-alt"></i>{" "}
                            {formatDate(blog.date)}
                          </li>
                          <li>
                            <i className="fas fa-user"></i> By{" "}
                            <a href="#">{blog.author}</a>
                          </li>
                        </ul>
                      </div>
                      <h4>
                        <Link to={`/blog/${blog._id}`}>{blog.title}</Link>
                      </h4>
                    </div>
                  </div>
                </div>
              ))}
              {/* End Single Item */}
            </div>
          </div>
        </div>
      </div>
      {/* End Blog Area */}
    </>
  );
};

export default Home;
