import React, { useState, useEffect } from "react";
import axios from "axios";

const AboutCompany = () => {
  const [formData, setFormData] = useState({
    banner_image: "",
    f_title: "",
    f_description: "",
    experience: "",
    faq_one: "",
    faq_one_ans: "",
    faq_two: "",
    faq_two_ans: "",
    faq_three: "",
    faq_three_ans: "",
    f_image1: "",
    f_image2: "",
    s_title: "",
    s_description: "",
    s_image: "",
    completed_projects: "",
    p_one: "",
    p_one_description: "",
    p_two: "",
    p_two_description: "",
    p_three: "",
    p_three_description: "",
    p_four: "",
    p_four_description: "",
  });

  const [aboutData, setAboutData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState(null);
  const [showForm, setShowForm] = useState(false); // New state for form visibility

  useEffect(() => {
    fetchAboutData();
  }, []);

  const fetchAboutData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_BACK}/api/aboutcompany`
      );
      setAboutData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataToSend = new FormData();
    for (const key in formData) {
      if (formData[key] instanceof File) {
        dataToSend.append(key, formData[key]);
      } else {
        dataToSend.append(key, formData[key]);
      }
    }
    try {
      if (editMode) {
        await axios.put(
          `${process.env.REACT_APP_API_URL_BACK}/api/aboutcompany/${editId}`,
          dataToSend
        );
      } else {
        await axios.post(
          `${process.env.REACT_APP_API_URL_BACK}/api/aboutcompany`,
          dataToSend
        );
      }
      setFormData({
        banner_image: "",
        f_title: "",
        f_description: "",
        experience: "",
        faq_one: "",
        faq_one_ans: "",
        faq_two: "",
        faq_two_ans: "",
        faq_three: "",
        faq_three_ans: "",
        f_image1: "",
        f_image2: "",
        s_title: "",
        s_description: "",
        s_image: "",
        completed_projects: "",
        p_one: "",
        p_one_description: "",
        p_two: "",
        p_two_description: "",
        p_three: "",
        p_three_description: "",
        p_four: "",
        p_four_description: "",
      });
      setEditMode(false);
      setShowForm(false); // Hide form after submission
      fetchAboutData();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleEdit = (data) => {
    setFormData({
      banner_image: data.banner_image,
      f_title: data.f_title,
      f_description: data.f_description,
      experience: data.experience,
      faq_one: data.faq_one,
      faq_one_ans: data.faq_one_ans,
      faq_two: data.faq_two,
      faq_two_ans: data.faq_two_ans,
      faq_three: data.faq_three,
      faq_three_ans: data.faq_three_ans,
      f_image1: data.f_image1,
      f_image2: data.f_image2,
      s_title: data.s_title,
      s_description: data.s_description,
      s_image: data.s_image,
      completed_projects: data.completed_projects,
      p_one: data.p_one,
      p_one_description: data.p_one_description,
      p_two: data.p_two,
      p_two_description: data.p_two_description,
      p_three: data.p_three,
      p_three_description: data.p_three_description,
      p_four: data.p_four,
      p_four_description: data.p_four_description,
    });
    setEditId(data._id);
    setEditMode(true);
    setShowForm(true); // Show form in edit mode
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL_BACK}/api/aboutcompany/${id}`
      );
      fetchAboutData();
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  return (
    <div className="container mt-5">
      <h2>About Company</h2>
      <button
        className="btn btn-primary mb-3"
        onClick={() => {
          setShowForm(!showForm); // Toggle form visibility
          setEditMode(false); // Reset edit mode when adding new data
        }}>
        {showForm ? "Cancel" : "Add Data"}
      </button>
      {showForm && (
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="banner_image">Banner Image</label>
              <input
                type="file"
                className="form-control"
                id="banner_image"
                name="banner_image"
                onChange={handleFileChange}
              />
              <img
                src={
                  formData.banner_image
                    ? `${process.env.REACT_APP_API_URL_BACK}/${formData.banner_image}`
                    : "default-image-path.jpg"
                }
                alt="Banner"
                style={{ width: "150px", height: "50px", objectFit: "cover" }}
              />
            </div>

            <div className="form-group col-md-3">
              <label htmlFor="f_image1">First Image 1</label>
              <input
                type="file"
                className="form-control"
                id="f_image1"
                name="f_image1"
                onChange={handleFileChange}
              />
              <img
                src={
                  formData.banner_image
                    ? `${process.env.REACT_APP_API_URL_BACK}/${formData.f_image1}`
                    : "default-image-path.jpg"
                }
                alt="Banner"
                style={{ width: "150px", height: "50px", objectFit: "cover" }}
              />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="f_image2">First Image 2</label>
              <input
                type="file"
                className="form-control"
                id="f_image2"
                name="f_image2"
                onChange={handleFileChange}
              />
              <img
                src={
                  formData.banner_image
                    ? `${process.env.REACT_APP_API_URL_BACK}/${formData.f_image2}`
                    : "default-image-path.jpg"
                }
                alt="Banner"
                style={{ width: "150px", height: "50px", objectFit: "cover" }}
              />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="s_image">Second Image</label>
              <input
                type="file"
                className="form-control"
                id="s_image"
                name="s_image"
                onChange={handleFileChange}
              />
              <img
                src={
                  formData.banner_image
                    ? `${process.env.REACT_APP_API_URL_BACK}/${formData.s_image}`
                    : "default-image-path.jpg"
                }
                alt="Banner"
                style={{ width: "150px", height: "50px", objectFit: "cover" }}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="f_title">First Title</label>
              <input
                type="text"
                className="form-control"
                id="f_title"
                name="f_title"
                value={formData.f_title}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group col-md-3">
              <label htmlFor="f_description">First Description</label>
              <input
                type="text"
                className="form-control"
                id="f_description"
                name="f_description"
                value={formData.f_description}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group col-md-3">
              <label htmlFor="experience">Experience</label>
              <input
                type="text"
                className="form-control"
                id="experience"
                name="experience"
                value={formData.experience}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="completed_projects">Completed Projects</label>
              <input
                type="text"
                className="form-control"
                id="completed_projects"
                name="completed_projects"
                value={formData.completed_projects}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="faq_one">FAQ 1</label>
              <input
                type="text"
                className="form-control"
                id="faq_one"
                name="faq_one"
                value={formData.faq_one}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="faq_one_ans">FAQ 1 Answer</label>
              <input
                type="text"
                className="form-control"
                id="faq_one_ans"
                name="faq_one_ans"
                value={formData.faq_one_ans}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="faq_two">FAQ 2</label>
              <input
                type="text"
                className="form-control"
                id="faq_two"
                name="faq_two"
                value={formData.faq_two}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="faq_two_ans">FAQ 2 Answer</label>
              <input
                type="text"
                className="form-control"
                id="faq_two_ans"
                name="faq_two_ans"
                value={formData.faq_two_ans}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="faq_three">FAQ 3</label>
              <input
                type="text"
                className="form-control"
                id="faq_three"
                name="faq_three"
                value={formData.faq_three}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="faq_three_ans">FAQ 3 Answer</label>
              <input
                type="text"
                className="form-control"
                id="faq_three_ans"
                name="faq_three_ans"
                value={formData.faq_three_ans}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="s_title">Second Title</label>
              <input
                type="text"
                className="form-control"
                id="s_title"
                name="s_title"
                value={formData.s_title}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="s_description">Second Description</label>
              <input
                type="text"
                className="form-control"
                id="s_description"
                name="s_description"
                value={formData.s_description}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="p_one">Process 1 Title</label>
              <input
                type="text"
                className="form-control"
                id="p_one"
                name="p_one"
                value={formData.p_one}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="p_one_description">Process 1 Description</label>
              <input
                type="text"
                className="form-control"
                id="p_one_description"
                name="p_one_description"
                value={formData.p_one_description}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="p_two">Process 2 Title</label>
              <input
                type="text"
                className="form-control"
                id="p_two"
                name="p_two"
                value={formData.p_two}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="p_two_description">Process 2 Description</label>
              <input
                type="text"
                className="form-control"
                id="p_two_description"
                name="p_two_description"
                value={formData.p_two_description}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="p_three">Process 3 Title</label>
              <input
                type="text"
                className="form-control"
                id="p_three"
                name="p_three"
                value={formData.p_three}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="p_three_description">Process 3 Description</label>
              <input
                type="text"
                className="form-control"
                id="p_three_description"
                name="p_three_description"
                value={formData.p_three_description}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="p_four">Process 4 Title</label>
              <input
                type="text"
                className="form-control"
                id="p_four"
                name="p_four"
                value={formData.p_four}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="p_four_description">Project 4 Description</label>
              <input
                type="text"
                className="form-control"
                id="p_four_description"
                name="p_four_description"
                value={formData.p_four_description}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <button type="submit" className="btn btn-primary">
            {editMode ? "Update" : "Add"}
          </button>
        </form>
      )}
      <table className="table">
        <thead>
          <tr>
            <th>Banner</th>
            <th>Feature Title</th>
            <th>Feature Description</th>
            <th>Experience</th>
            <th>Projects</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {aboutData.map((data) => (
            <tr key={data._id}>
              <td>
                <img
                  src={`${process.env.REACT_APP_API_URL_BACK}/${data.banner_image}`}
                  alt="Banner"
                  width="100"
                />
              </td>
              <td>{data.f_title}</td>
              <td>{data.f_description}</td>
              <td>{data.experience}</td>
              <td>{data.completed_projects}</td>
              <td>
                <button onClick={() => handleEdit(data)}>
                  <i className="fas fa-pen"></i>
                </button>
                <button
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to delete this item?"
                      )
                    ) {
                      handleDelete(data._id);
                    }
                  }}>
                  <i className="fas fa-trash"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AboutCompany;
