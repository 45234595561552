import React from "react";
import Navbar from "./partial/backend/Navbar";
import Sidebar from "./partial/backend/Sidebar";
import Footer from "./partial/backend/Footer";
import "./Css/Dashboard.css"; // Updated path for custom styles

const Dashboard = () => {
  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex flex-grow-1">
        <main className="content flex-grow-1 bg-light-grey">
          <div className="container-fluid">
            <h1 className="my-4">Welcome to Tech Vision</h1>
            {/* Dashboard content goes here */}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
