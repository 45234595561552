import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import bannerImage from "../../assets/img/banner/9.jpg";
import whoWeImage from "../../assets/img/banner/6.jpg";

const Whychoose = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL_BACK}/api/whychoose`
        );
        setItems(response.data);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {/* Start Breadcrumb */}
      <div
        className="breadcrumb-area shadow dark bg-fixed text-light"
        style={{ backgroundImage: `url(${bannerImage})` }}>
        <div className="container">
          <div className="row align-center">
            <div className="col-lg-6">
              <h2>Why choose us</h2>
            </div>
            <div className="col-lg-6 text-right">
              <ul className="breadcrumb">
                <li>
                  <Link to="/">
                    <i className="fas fa-home"></i> Home
                  </Link>
                </li>
                <li>
                  <Link to="#">Pages</Link>
                </li>
                <li className="active">Why choose us</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrumb */}

      {/* Start Features Area */}
      <div className="feature-area overflow-hidden default-padding">
        <div className="container">
          <div className="heading-left">
            <div className="row">
              <div className="col-lg-6">
                <h5>6+ years of experience</h5>
                <h2>
                  Offer The Latest Software And Solutions To Our customers!
                </h2>
              </div>
              <div className="col-lg-6">
                <p>
                  "We offer the latest software and innovative solutions to our
                  customers, ensuring they stay ahead in today’s fast-paced
                  digital world. Our cutting-edge technologies and tailored
                  approaches are designed to meet evolving needs, helping
                  businesses optimize performance, enhance efficiency, and
                  achieve their goals with the most advanced tools available."
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="features-box text-light">
            <div className="row">
              {/* Single Item */}
              {items.map((item) => (
                <div className="single-item col-lg-4 col-md-6" key={item._id}>
                  <div className="item">
                    {/* Display the first image and title from each item */}
                    <img src={item.image} alt={item.title} />
                    <div className="overlay">
                      <div className="info">
                        {/* Display the first title from each item */}
                        <h4>{item.title}</h4>
                        <Link to="#">
                          <i className="fas fa-angle-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {/* End Single Item */}
            </div>
          </div>
        </div>
      </div>
      {/* End Features Area */}

      {/* Start Who We Area */}
      <div className="who-we-area bg-dark text-light">
        <div className="container-fluid">
          <div className="who-we-area-box">
            <div className="row">
              <div
                className="col-lg-6 thumb bg-cover"
                style={{ backgroundImage: `url(${whoWeImage})` }}></div>
              <div className="col-lg-6 info">
                <div className="row">
                  <div className="col-lg-6 col-md-6 item">
                    <h4>Our Mission</h4>
                    <h2 className="text-blur">Mission</h2>
                    <p>
                      To be the leading provider of innovative IT solutions,
                      empowering businesses with cutting-edge technology to
                      drive digital transformation and achieve sustainable
                      growth.
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 item">
                    <h4>Our Vision</h4>
                    <h2 className="text-blur">Vision</h2>
                    <p>
                      To deliver exceptional software and services that exceed
                      client expectations through continuous innovation,
                      personalized support, and a commitment to excellence. We
                      strive to enhance operational efficiency and foster
                      success for our customers in an ever-evolving digital
                      landscape.
                    </p>
                    <Link
                      to="/contactus"
                      className="btn circle btn-light effect btn-sm">
                      Know More{" "}
                      <i className="fas fa-arrow-alt-circle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Who We Area */}

      {/* Start Faq Area */}
      <div className="faq-area default-padding">
        <div className="container">
          <div className="faq-items">
            <div className="row">
              <div className="col-lg-5 info">
                <h5>faq</h5>
                <h2>Most common question about our services</h2>
                <Link to="#" className="btn btn-theme effect btn-md">
                  View All
                </Link>
              </div>
              <div className="col-lg-7">
                <div className="faq-content">
                  <div className="accordion" id="accordionExample">
                    <div className="card">
                      <div className="card-header" id="headingOne">
                        <h4
                          className="mb-0"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne">
                          <strong>?</strong> Do I need a business plan
                        </h4>
                      </div>
                      <div
                        id="collapseOne"
                        className="collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#accordionExample">
                        <div className="card-body">
                          <p>
                            Continue building numerous of at relation in
                            margaret. Lasted engage roused mother an am at.
                            Other early while if by do to. Missed living excuse
                            as be. Cause heard fat above first time achievement.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="headingTwo">
                        <h4
                          className="mb-0 collapsed"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo">
                          <strong>?</strong> How long should a business plan be
                        </h4>
                      </div>
                      <div
                        id="collapseTwo"
                        className="collapse"
                        aria-labelledby="headingTwo"
                        data-parent="#accordionExample">
                        <div className="card-body">
                          <p>
                            Continue building numerous of at relation in
                            margaret. Lasted engage roused mother an am at.
                            Other early while if by do to. Missed living excuse
                            as be. Cause heard fat above first time achievement.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="headingFour">
                        <h4
                          className="mb-0 collapsed"
                          data-toggle="collapse"
                          data-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour">
                          <strong>?</strong> Where do I start
                        </h4>
                      </div>
                      <div
                        id="collapseFour"
                        className="collapse"
                        aria-labelledby="headingFour"
                        data-parent="#accordionExample">
                        <div className="card-body">
                          <p>
                            Continue building numerous of at relation in
                            margaret. Lasted engage roused mother an am at.
                            Other early while if by do to. Missed living excuse
                            as be. Cause heard fat above first time achievement.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Faq Area */}
    </>
  );
};

export default Whychoose;
