import React from "react";

const NotFound = () => {
  return (
    <div style={{ textAlign: "center", padding: "50px" }}>
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for doesn't exist or an error occurred.</p>
    </div>
  );
};

export default NotFound;
