import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import bannerImage from "../../assets/img/banner/15.jpg";

const Services = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL_BACK}/api/service`
        );
        setServices(response.data);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      {/* Start Breadcrumb */}
      <div
        className="breadcrumb-area shadow dark bg-fixed text-light"
        style={{ backgroundImage: `url(${bannerImage})` }}>
        <div className="container">
          <div className="row align-center">
            <div className="col-lg-6">
              <h2>Our Services</h2>
            </div>
            <div className="col-lg-6 text-right">
              <ul className="breadcrumb">
                <li>
                  <Link to="/">
                    <i className="fas fa-home"></i> Home
                  </Link>
                </li>
                <li>
                  <Link to="#">Pages</Link>
                </li>
                <li className="active">Services</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrumb */}

      {/* Start Services */}
      <div className="service-area default-padding bottom-less bg-cover">
        <div className="container">
          <div className="service-items text-center">
            <div className="row">
              {services.map((service) => (
                <div
                  key={service._id}
                  className="col-lg-4 col-md-6 single-item">
                  <div className="item">
                    <div className="info">
                      <h4>{service.sname}</h4>
                      <i className={service.icon}></i>
                      <p>{service.sdetails}</p>
                      <Link
                        className="btn-standard"
                        to={`/service/${service._id}`}>
                        Discover More
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* End Services Area */}
    </>
  );
};

export default Services;
