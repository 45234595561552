import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import logoLight from "../../assets/img/logo-light.png";
import logo from "../../assets/img/logo.png";

const Navbar = () => {
  const [services, setServices] = useState([]);
  const [settings, setSettings] = useState({
    logo: "",
    contact: "",
  });

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL_BACK}/api/settings`
        );
        if (response.data.length > 0) {
          setSettings(response.data[0]); // Assuming you are fetching a single setting object
        }
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };

    fetchSettings();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL_BACK}/api/service`
        );
        setServices(response.data);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);

  const LogoURL =
    settings && settings.logo
      ? `${process.env.REACT_APP_API_URL_BACK}/${settings.logo}`
      : logoLight;

  return (
    <header id="home">
      <nav className="navbar navbar-default navbar-fixed white no-background bootsnav">
        <div className="container">
          <div className="nav-box">
            <div className="attr-nav inc-border">
              <ul>
                <li className="contact">
                  <i className="flaticon-telephone"></i>
                  <p>
                    Call us today! <strong>{settings.contact}</strong>
                  </p>
                </li>
              </ul>
            </div>
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle"
                data-toggle="collapse"
                data-target="#navbar-menu">
                <i className="fa fa-bars"></i>
              </button>
              <Link className="navbar-brand" to="/">
                <img
                  src={LogoURL}
                  className="logo logo-display"
                  alt="Logo"
                  style={{ height: "55px" }}
                />
                <img
                  src={logo}
                  className="logo logo-scrolled"
                  alt="Logo"
                  style={{ height: "55px" }}
                />
              </Link>
            </div>
            <div className="collapse navbar-collapse" id="navbar-menu">
              <ul
                className="nav navbar-nav navbar-center"
                data-in="fadeInDown"
                data-out="fadeOutUp">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="dropdown">
                  <a
                    href="#"
                    className="dropdown-toggle"
                    data-toggle="dropdown">
                    Company
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <Link to="/about">About Company</Link>
                    </li>
                    <li>
                      <Link to="/whychooseus">Why Choose us</Link>
                    </li>
                    <li>
                      <Link to="/team">Meet Our Team</Link>
                    </li>
                  </ul>
                </li>
                <li className="dropdown">
                  <a
                    href="#"
                    className="dropdown-toggle"
                    data-toggle="dropdown">
                    Our Services
                  </a>
                  <ul className="dropdown-menu">
                    {services.map((service) => (
                      <li key={service._id}>
                        <Link to={`/service/${service._id}`}>
                          {service.sname}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <Link to="/works">Works</Link>
                </li>
                <li>
                  <Link to="/blogs">Blogs</Link>
                </li>
                <li>
                  <Link to="/contactus">Contact</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
