import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

// Frontend Import
import Header from "./components/partial/Header";
import Navbar from "./components/partial/Navbar";
import Footer from "./components/partial/Footer";
import NotFound from "./components/pages/NotFound"; // Import the 404 component
import Home from "./components/Home";
import Aboutus from "./components/pages/Aboutus";
import Whychoose from "./components/pages/Whychoose";
import Team from "./components/pages/Team";
import Services from "./components/pages/Services";
import ServiceDetails from "./components/pages/ServiceDetails";
import Works from "./components/pages/Works";
import WorkDetails from "./components/pages/WorkDetails";
import Blogs from "./components/pages/Blogs";
import BlogDetails from "./components/pages/BlogDetails";
import Contact from "./components/pages/Contact";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import ProtectedPage from "./components/ProtectedPage"; // Example protected page
import ProtectedRoute from "./components/ProtectedRoute";
import Dashboard from "./components/Dashboard";

// Backend Import
import BackendLayout from "./components/layouts/BackendLayout"; // Layout
import UserManagement from "./components/pages/backend/UserManagement";
import Settings from "./components/pages/backend/Settings";
import AboutCompany from "./components/pages/backend/AboutCompany";
import WhyChoose from "./components/pages/backend/WhyChoose";
import Teams from "./components/pages/backend/Team";
import Service from "./components/pages/backend/Service";
import Work from "./components/pages/backend/Work";
import Blog from "./components/pages/backend/Blog";
import Contactus from "./components/pages/backend/Contact";
import Slider from "./components/pages/backend/Slider";

const Layout = ({ children }) => {
  const location = useLocation();
  const isAuthRoute =
    location.pathname === "/login" || location.pathname === "/register";

  return (
    <>
      {!isAuthRoute && <Header />}
      {!isAuthRoute && <Navbar />}
      <main>{children}</main>
      {!isAuthRoute && <Footer />}
    </>
  );
};

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          path="/about"
          element={
            <Layout>
              <Aboutus />
            </Layout>
          }
        />
        <Route
          path="/whychooseus"
          element={
            <Layout>
              <Whychoose />
            </Layout>
          }
        />
        <Route
          path="/team"
          element={
            <Layout>
              <Team />
            </Layout>
          }
        />
        <Route
          path="/services"
          element={
            <Layout>
              <Services />
            </Layout>
          }
        />
        <Route
          path="/service/:id"
          element={
            <Layout>
              <ServiceDetails />
            </Layout>
          }
        />
        <Route
          path="/works"
          element={
            <Layout>
              <Works />
            </Layout>
          }
        />
        <Route
          path="/work/:id"
          element={
            <Layout>
              <WorkDetails />
            </Layout>
          }
        />
        <Route
          path="/blogs"
          element={
            <Layout>
              <Blogs />
            </Layout>
          }
        />
        <Route
          path="/blog/:id"
          element={
            <Layout>
              <BlogDetails />
            </Layout>
          }
        />
        <Route
          path="/contactus"
          element={
            <Layout>
              <Contact />
            </Layout>
          }
        />
        <Route path="*" element={<NotFound />} />
        {/* Auth Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/protected" element={<ProtectedPage />} />

        {/* Protected Route */}
        {/* Backend Routes */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <BackendLayout>
                <Dashboard />
              </BackendLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/user-management"
          element={
            <ProtectedRoute>
              <BackendLayout>
                <UserManagement />
              </BackendLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/settings"
          element={
            <ProtectedRoute>
              <BackendLayout>
                <Settings />
              </BackendLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/aboutcompany"
          element={
            <ProtectedRoute>
              <BackendLayout>
                <AboutCompany />
              </BackendLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/whychooseus"
          element={
            <ProtectedRoute>
              <BackendLayout>
                <WhyChoose />
              </BackendLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/team"
          element={
            <ProtectedRoute>
              <BackendLayout>
                <Teams />
              </BackendLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/service"
          element={
            <ProtectedRoute>
              <BackendLayout>
                <Service />
              </BackendLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/work"
          element={
            <ProtectedRoute>
              <BackendLayout>
                <Work />
              </BackendLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/blog"
          element={
            <ProtectedRoute>
              <BackendLayout>
                <Blog />
              </BackendLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/contact"
          element={
            <ProtectedRoute>
              <BackendLayout>
                <Contactus />
              </BackendLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/slider"
          element={
            <ProtectedRoute>
              <BackendLayout>
                <Slider />
              </BackendLayout>
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
