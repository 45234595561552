import React from "react";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import "../../assets/css/bootsnav.css";
// import "../../assets/css/themify-icons.css";
import "../../assets/css/flaticon-set.css";
import "../../assets/css/magnific-popup.css";
import "../../assets/css/owl.carousel.min.css";
import "../../assets/css/owl.theme.default.min.css";
import "../../assets/css/animate.css";
import "../../assets/css/responsive.css";

const Header = () => {
  return (
    <header>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="description" content="Tanda - IT Solutions Template" />
      <title>
        Tech Vision IT Solution -Software Company | IT Consultant | Mastering
        the Art of Digital Transformation
      </title>
      <link
        rel="shortcut icon"
        href="../../assets/img/favicon.png"
        type="image/x-icon"
      />
      {/* Add additional header content here */}
      <link
        href="https://cdn.jsdelivr.net/gh/hung1001/font-awesome-pro@4cac1a6/css/all.css"
        rel="stylesheet"
        type="text/css"
      />
    </header>
  );
};

export default Header;
