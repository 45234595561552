import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import bannerImage from "../../assets/img/banner/15.jpg";

const Team = () => {
  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL_BACK}/api/team`
        );
        setTeamMembers(response.data);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {/* Start Breadcrumb */}
      <div
        className="breadcrumb-area shadow dark bg-fixed text-light"
        style={{ backgroundImage: `url(${bannerImage})` }}>
        <div className="container">
          <div className="row align-center">
            <div className="col-lg-6">
              <h2>Team Members</h2>
            </div>
            <div className="col-lg-6 text-right">
              <ul className="breadcrumb">
                <li>
                  <Link to="/">
                    <i className="fas fa-home"></i> Home
                  </Link>
                </li>
                <li>
                  <Link to="#">Pages</Link>
                </li>
                <li className="active">Team</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrumb */}

      {/* Start Team */}
      <div className="team-area default-padding bottom-less">
        <div className="container">
          <div className="team-items text-center">
            <div className="row">
              {teamMembers.map((member, index) => (
                <div key={index} className="single-item col-lg-4 col-md-6">
                  <div className="item">
                    <div className="thumb">
                      <img src={member.image} alt={`${member.name}`} />
                      <div className="social">
                        <input
                          type="checkbox"
                          id={`toggle-${index}`}
                          className="share-toggle"
                          hidden
                        />
                        <label
                          htmlFor={`toggle-${index}`}
                          className="share-button">
                          <i className="fas fa-plus"></i>
                        </label>
                        <a
                          href={member.facebook}
                          className="share-icon facebook"
                          target="_blank">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                        <a
                          href={member.linkedin}
                          className="share-icon twitter"
                          target="_blank">
                          <i className="fab fa-linkedin"></i>
                        </a>
                      </div>
                    </div>
                    <div className="info">
                      <h4>{member.name}</h4>
                      <span>{member.position}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* End Team */}
    </>
  );
};

export default Team;
