import React, { useState, useEffect } from "react";
import axios from "axios";
import banner from "../../assets/img/banner/2.jpg";
import shape9 from "../../assets/img/shape/9.png";

const Aboutus = () => {
  const [aboutCompany, setaboutCompany] = useState({
    banner_image: "",
    f_title: "",
    f_description: "",
    experience: "",
    faq_one: "",
    faq_one_ans: "",
    faq_two: "",
    faq_two_ans: "",
    faq_three: "",
    faq_three_ans: "",
    f_image1: "",
    f_image2: "",
    s_title: "",
    s_description: "",
    s_image: "",
    completed_projects: "",
    p_one: "",
    p_one_description: "",
    p_two: "",
    p_two_description: "",
    p_three: "",
    p_three_description: "",
    p_four: "",
    p_four_description: "",
  });

  useEffect(() => {
    const fetchaboutCompany = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL_BACK}/api/aboutcompany`
        );
        if (response.data.length > 0) {
          setaboutCompany(response.data[0]); // Assuming you are fetching a single setting object
        }
      } catch (error) {
        console.error("Error fetching About Company Data:", error);
      }
    };

    fetchaboutCompany();
  }, []);

  // Correct the URL format
  const correctedBannerImage = aboutCompany.banner_image.replace(/\\/g, "/");
  const decodedBannerImage = decodeURIComponent(correctedBannerImage);
  return (
    <>
      {/* Start Breadcrumb */}
      <div
        className="breadcrumb-area shadow dark bg-fixed text-light"
        style={{
          backgroundImage: `url(${decodedBannerImage})`,
        }}>
        <div className="container">
          <div className="row align-center">
            <div className="col-lg-6">
              <h2>About Tech Vision</h2>
            </div>
            <div className="col-lg-6 text-right">
              <ul className="breadcrumb">
                <li>
                  <a href="#">
                    <i className="fas fa-home"></i> Home
                  </a>
                </li>
                <li>
                  <a href="#">Pages</a>
                </li>
                <li className="active">About Tech Vision</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrumb */}

      {/* Start About Area */}
      <div className="about-area faq-area inc-shape default-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="thumb">
                <img src={aboutCompany.f_image1} alt="Thumb 1" />
                <img src={aboutCompany.f_image2} alt="Thumb 2" />
                <div className="overlay">
                  <div className="content">
                    <h4>{aboutCompany.experience}+ years of experience</h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-5 offset-lg-1 info">
              <h2>{aboutCompany.f_title}</h2>
              <p>{aboutCompany.f_description}</p>
              <div className="faq-content accordion" id="accordionExample">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h4
                      className="mb-0"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne">
                      {aboutCompany.faq_one}
                    </h4>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample">
                    <div className="card-body">
                      <p>{aboutCompany.faq_one_ans}</p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <h4
                      className="mb-0 collapsed"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo">
                      {aboutCompany.faq_two}
                    </h4>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample">
                    <div className="card-body">
                      <p>{aboutCompany.faq_two_ans}</p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingFour">
                    <h4
                      className="mb-0 collapsed"
                      data-toggle="collapse"
                      data-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour">
                      {aboutCompany.faq_three}
                    </h4>
                  </div>
                  <div
                    id="collapseFour"
                    className="collapse"
                    aria-labelledby="headingFour"
                    data-parent="#accordionExample">
                    <div className="card-body">
                      <p>{aboutCompany.faq_three_ans}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area */}

      {/* Start Works About */}
      <div className="works-about-area overflow-hidden">
        <div className="container">
          <div className="works-about-items default-padding">
            <div className="row align-center">
              <div className="col-lg-6 info">
                <h5>Works About</h5>
                <h2>{aboutCompany.s_title}</h2>
                <p>{aboutCompany.s_description}</p>
                <ul>
                  <li>
                    <h5>100% Client Satisfaction</h5>
                  </li>
                  <li>
                    <h5>Best Worker</h5>
                  </li>
                </ul>
                <a className="btn btn-theme effect btn-sm" href="/contactus">
                  Talk to a consultant
                </a>
              </div>
              <div className="col-lg-6">
                <div className="thumb">
                  <img src={aboutCompany.s_image} alt="Thumb" />
                  <div className="fun-fact">
                    <div className="timer" data-to="875" data-speed="5000">
                      {aboutCompany.completed_projects}
                    </div>
                    <span className="medium">Completed Projects</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Works About Area */}

      {/* Start Work Process */}
      <div className="work-process-area overflow-hidden default-padding bottom-less">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="site-heading text-center">
                <h4>Process</h4>
                <h2>How we work</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container-full">
          <div className="work-pro-items">
            <div className="row">
              {/* Single Item */}
              <div className="single-item col-lg-3 col-md-6">
                <div className="item">
                  <div className="item-inner">
                    <div className="icon">
                      <i className="flaticon-select"></i>
                      <span>01</span>
                    </div>
                    <h5>{aboutCompany.p_one}</h5>
                    <p>{aboutCompany.p_one_description}</p>
                  </div>
                </div>
              </div>
              {/* End Single Item */}
              {/* Single Item */}
              <div className="single-item col-lg-3 col-md-6">
                <div className="item">
                  <div className="item-inner">
                    <div className="icon">
                      <i className="flaticon-video-call"></i>
                      <span>02</span>
                    </div>
                    <h5>{aboutCompany.p_two}</h5>
                    <p>{aboutCompany.p_two_description}</p>
                  </div>
                </div>
              </div>
              {/* End Single Item */}
              {/* Single Item */}
              <div className="single-item col-lg-3 col-md-6">
                <div className="item">
                  <div className="item-inner">
                    <div className="icon">
                      <i className="flaticon-strategy"></i>
                      <span>03</span>
                    </div>
                    <h5>{aboutCompany.p_three}</h5>
                    <p>{aboutCompany.p_three_description}</p>
                  </div>
                </div>
              </div>
              {/* End Single Item */}
              {/* Single Item */}
              <div className="single-item col-lg-3 col-md-6">
                <div className="item">
                  <div className="item-inner">
                    <div className="icon">
                      <i className="flaticon-help"></i>
                      <span>04</span>
                    </div>
                    <h5>{aboutCompany.p_four}</h5>
                    <p>{aboutCompany.p_four_description}</p>
                  </div>
                </div>
              </div>
              {/* End Single Item */}
            </div>
          </div>
        </div>
      </div>
      {/* End Work Process Area */}

      {/* Start Video Area */}
      <div
        className="video-area extra-padding text-center default-padding faq-area bg-gray bg-fixed shadow dark text-light"
        style={{ backgroundImage: `url(${banner})` }}>
        <div className="container">
          <div className="content">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <h5>Why Choose Us</h5>
                <h2>
                  We Provide Outsourced IT Services
                  <br /> For your business
                </h2>
                <a
                  className="popup-youtube relative video-play-button"
                  href="https://www.youtube.com/">
                  <i className="fa fa-play"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* Fixed Shape Bottom */}
        <div className="fixed-shape-bottom">
          <img src={shape9} alt="Shape" />
        </div>
        {/* End Fixed Shape Bottom */}
      </div>
      {/* End Video Area */}

      {/* Start Why Choose Us */}
      <div className="choose-us-area default-padding-bottom">
        <div className="container">
          <div className="items-box">
            <div className="row">
              {/* Single Item */}
              <div className="single-item col-lg-6 col-mg-6">
                <div className="item">
                  <div className="info">
                    <h4>24/7 Customer support</h4>
                    <p>
                      Available 24/7, our dedicated support team is always ready
                      to assist you with any issues or questions.
                    </p>
                    <div className="call">
                      <div className="icons">
                        <i className="fas fa-phone"></i>
                      </div>
                      <div className="info">
                        <span>Helpline</span> +977 9817447999
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Single Item */}
              {/* Single Item */}
              <div className="single-item col-lg-6 col-mg-6">
                <div className="item">
                  <div className="icon">
                    <i className="fas fa-cubes"></i>
                  </div>
                  <div className="info">
                    <h4>Smart solutions</h4>
                    <p>
                      Efficient and intelligent solutions tailored to meet your
                      unique business needs.
                    </p>
                    <a href="/contactus" className="btn-more">
                      Start Now
                    </a>
                  </div>
                </div>
              </div>
              {/* End Single Item */}
            </div>
          </div>
        </div>
      </div>
      {/* End Why Choose Us Area */}
    </>
  );
};

export default Aboutus;
