import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import bannerImage from "../../assets/img/banner/15.jpg";

const Works = () => {
  const [works, setWorks] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL_BACK}/api/work`
        );
        setWorks(response.data);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      {/* Start Breadcrumb */}
      <div
        className="breadcrumb-area shadow dark bg-fixed text-light"
        style={{ backgroundImage: `url(${bannerImage})` }}>
        <div className="container">
          <div className="row align-center">
            <div className="col-lg-6">
              <h2>Our Works</h2>
            </div>
            <div className="col-lg-6 text-right">
              <ul className="breadcrumb">
                <li>
                  <Link to="/">
                    <i className="fas fa-home"></i> Home
                  </Link>
                </li>
                <li>
                  <Link to="#">Pages</Link>
                </li>
                <li className="active">Our Works</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrumb */}

      {/* Start Case Studies Area */}
      <div className="case-studies-area overflow-hidden grid-items default-padding">
        <div className="container-full">
          <div className="case-items-area">
            <div className="masonary">
              <div id="portfolio-grid" className="case-items colums-3">
                {works.map((work, index) => (
                  <div key={index} className="pf-item">
                    <div className="item">
                      <div className="thumb">
                        <img
                          src={work.cover}
                          alt={work.title}
                          style={{
                            width: "800px",
                            height: "200px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div className="info">
                        <div className="info-items">
                          <div className="left-info">
                            <h4>
                              <Link to={`/work/${work._id}`} target="_blank">
                                {work.client_name}
                              </Link>
                            </h4>
                            <ul>
                              <li>{work.category}</li>
                            </ul>
                          </div>
                          <div className="right-info">
                            <a className="item popup-gallery" href={work.image}>
                              <i className="fas fa-plus"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Case Studies Area */}
    </>
  );
};

export default Works;
