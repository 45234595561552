import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import "../Css/WorkDetails.css";
import bannerImage from "../../assets/img/banner/15.jpg";

const WorkDetails = () => {
  const [workDetails, setWorkDetails] = useState(null);
  const { id } = useParams(); // Get the work id from the URL

  useEffect(() => {
    const fetchworkDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL_BACK}/api/work/${id}`
        );
        setWorkDetails(response.data);
      } catch (error) {
        console.error("Error fetching service details", error);
      }
    };

    fetchworkDetails();
  }, [id]);

  // Conditionally render content only if service is available
  if (!workDetails) {
    return <div>Loading...</div>; // You can show a loader or message here
  }

  const coverImage =
    workDetails && workDetails.cover
      ? `${process.env.REACT_APP_API_URL_BACK}/${workDetails.cover}`
      : coverImage;

  const Image_One =
    workDetails && workDetails.cover
      ? `${process.env.REACT_APP_API_URL_BACK}/${workDetails.image_one}`
      : Image_One;

  const Image_Two =
    workDetails && workDetails.cover
      ? `${process.env.REACT_APP_API_URL_BACK}/${workDetails.image_two}`
      : Image_Two;

  const Image_Three =
    workDetails && workDetails.cover
      ? `${process.env.REACT_APP_API_URL_BACK}/${workDetails.image_three}`
      : Image_Three;

  const techArray = workDetails?.technology
    ? workDetails.technology.split(",")
    : [];
  return (
    <>
      {/* Start Breadcrumb */}
      <div
        className="breadcrumb-area shadow dark bg-fixed text-light"
        style={{ backgroundImage: `url(${bannerImage})` }}>
        <div className="container">
          <div className="row align-center">
            <div className="col-lg-6">
              <h2>Works of {workDetails.client_name}</h2>
            </div>
            <div className="col-lg-6 text-right">
              <ul className="breadcrumb">
                <li>
                  <Link to="/">
                    <i className="fas fa-home"></i> Home
                  </Link>
                </li>
                <li>
                  <Link to="#">Pages</Link>
                </li>
                <li className="active">Work Details</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrumb */}

      {/* Start Work Details */}
      <div className="case-details-area services-details-area default-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 thumb">
              <img src={coverImage} alt="Thumb" />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 content">
              <div className="info">
                <ul className="project-info">
                  <li>
                    <div className="title">
                      <i className="fas fa-clock"></i> Date
                    </div>
                    <div className="details">{workDetails.date}</div>
                  </li>
                  <li>
                    <div className="title">
                      <i className="fas fa-user"></i> Client
                    </div>
                    <div className="details">{workDetails.client_name}</div>
                  </li>
                  <li>
                    <div className="title">
                      <i className="fas fa-bookmark"></i> Category
                    </div>
                    <div className="details">{workDetails.category}</div>
                  </li>
                  <li>
                    <div className="title">
                      <i className="fas fa-map-marked-alt"></i> Country
                    </div>
                    <div className="details">{workDetails.country}</div>
                  </li>
                </ul>
              </div>
              <h2>{workDetails.project_name}</h2>
              <p style={{ textAlign: "justify" }}>{workDetails.description}</p>
              <a className="btn-standard" href={workDetails.link}>
                Preview
              </a>
              <div className="row thumbs" style={{ paddingBottom: "50px" }}>
                <div className="col-lg-4 col-md-4 image-container">
                  <img
                    src={Image_One}
                    alt="Image One"
                    className="popup-image"
                  />
                </div>
                <div className="col-lg-4 col-md-4 image-container">
                  <img
                    src={Image_Two}
                    alt="Image Two"
                    className="popup-image"
                  />
                </div>
                <div className="col-lg-4 col-md-4 image-container">
                  <img
                    src={Image_Three}
                    alt="Image Three"
                    className="popup-image"
                  />
                </div>
              </div>
            </div>
            <div className="sidebar-item tags ">
              <div className="title">
                <h4>Technology Used :</h4>
              </div>
              <div className="sidebar-info">
                <ul>
                  {techArray.map((tech, index) => (
                    <li key={index}>
                      <a>{tech}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Work Details */}
    </>
  );
};

export default WorkDetails;
