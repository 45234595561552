import React, { useState, useEffect } from "react";
import axios from "axios";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    comments: "",
  });
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL_BACK}/api/contact`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        }
      );

      const result = await response.json();
      if (response.ok) {
        setMessage("Your message has been sent successfully!");
        setFormData({ name: "", email: "", phone: "", comments: "" });
      } else {
        setMessage(result.error || "Something went wrong!");
      }
    } catch (error) {
      setMessage("Error: Could not submit the form");
    }
  };

  const [settings, setSettings] = useState({
    logo: "",
    contact: "",
  });

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL_BACK}/api/settings`
        );
        if (response.data.length > 0) {
          setSettings(response.data[0]); // Assuming you are fetching a single setting object
        }
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };

    fetchSettings();
  }, []);
  return (
    <>
      {/* Start Breadcrumb */}
      <div
        className="breadcrumb-area shadow dark bg-fixed text-light"
        style={{
          backgroundImage: `url(${require("../../assets/img/banner/11.jpg")})`,
        }}>
        <div className="container">
          <div className="row align-center">
            <div className="col-lg-6">
              <h2>Contact Us</h2>
            </div>
            <div className="col-lg-6 text-right">
              <ul className="breadcrumb">
                <li>
                  <a href="#">
                    <i className="fas fa-home"></i> Home
                  </a>
                </li>
                <li>
                  <a href="#">Pages</a>
                </li>
                <li className="active">Contact</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrumb */}

      {/* Start Contact */}
      <div className="contact-area overflow-hidden default-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 contact-form-box">
              <div className="content">
                <div className="heading">
                  <h2>Need Help?</h2>
                  <p>Reach out to the world’s most reliable IT services.</p>
                </div>
                <form onSubmit={handleSubmit} className="contact-form">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          id="name"
                          name="name"
                          placeholder="Name*"
                          type="text"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                        <span className="alert-error"></span>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          id="email"
                          name="email"
                          placeholder="Email*"
                          type="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                        <span className="alert-error"></span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          className="form-control"
                          id="contact"
                          name="contact"
                          placeholder="Contact*"
                          type="text"
                          value={formData.contact}
                          onChange={handleChange}
                          required
                        />
                        <span className="alert-error"></span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group comments">
                        <textarea
                          className="form-control"
                          id="comments"
                          name="comments"
                          placeholder="Please describe what you need."
                          value={formData.comments}
                          onChange={handleChange}
                          required></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <button type="submit" name="submit" id="submit">
                        Get a free consultation
                      </button>
                    </div>
                  </div>
                  {message && (
                    <div className="col-md-12 alert-notification">
                      <div id="message" className="alert-msg">
                        {message}
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>

            <div className="col-lg-6 info">
              <div className="contact-tabs">
                <ul id="tabs" className="nav nav-tabs">
                  <li className="nav-item">
                    <a
                      href="#"
                      data-target="#tab1"
                      data-toggle="tab"
                      className="active nav-link">
                      Address
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#"
                      data-target="#tab2"
                      data-toggle="tab"
                      className="nav-link">
                      Google Maps
                    </a>
                  </li>
                </ul>
                <div id="tabsContent" className="tab-content">
                  <div id="tab1" className="tab-pane fade active show">
                    <ul>
                      <li>
                        <div className="icon">
                          <i className="fas fa-map-marker-alt"></i>
                        </div>
                        <div className="info">
                          <p>
                            Our Location
                            <span>{settings.address}</span>
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="fas fa-envelope-open"></i>
                        </div>
                        <div className="info">
                          <p>
                            Send Us Mail
                            <span>{settings.email}</span>
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="fas fa-phone"></i>
                        </div>
                        <div className="info">
                          <p>
                            Call Us
                            <span>{settings.contact}</span>
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  {/* <div id="tab2" className="tab-pane fade">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d14767.262289338461!2d70.79414485000001!3d22.284975!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1424308883981"
                      title="Google Maps"
                      allowFullScreen></iframe>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Contact Area */}
    </>
  );
};

export default Contact;
