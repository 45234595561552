import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import bannerImage from "../../assets/img/banner/15.jpg";

const ServiceDetails = () => {
  const [services, setServices] = useState([]);
  const [serviceDetails, setServiceDetails] = useState(null);
  const { id } = useParams(); // Get the service id from the URL

  useEffect(() => {
    const fetchServiceDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL_BACK}/api/service/${id}`
        );
        setServiceDetails(response.data);
      } catch (error) {
        console.error("Error fetching service details", error);
      }
    };

    fetchServiceDetails();
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL_BACK}/api/service`
        );
        setServices(response.data);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);

  // Conditionally render content only if service is available
  if (!serviceDetails) {
    return <div>Loading...</div>; // You can show a loader or message here
  }

  const imageUrl =
    serviceDetails && serviceDetails.cover
      ? `${process.env.REACT_APP_API_URL_BACK}/${serviceDetails.cover}`
      : bannerImage; // Fallback image if `cover` is not available

  return (
    <>
      {/* Start Breadcrumb */}
      <div
        className="breadcrumb-area shadow dark bg-fixed text-light"
        style={{ backgroundImage: `url(${bannerImage})` }}>
        <div className="container">
          <div className="row align-center">
            <div className="col-lg-6">
              <h2>{serviceDetails.sname}</h2>
            </div>
            <div className="col-lg-6 text-right">
              <ul className="breadcrumb">
                <li>
                  <Link to="/">
                    <i className="fas fa-home"></i> Home
                  </Link>
                </li>
                <li>
                  <Link to="#">Pages</Link>
                </li>
                <li className="active">Services Details</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrumb */}

      {/* Start Service Details */}
      <div className="services-details-area default-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 content">
              <div className="thumb">
                <img src={imageUrl} alt="Service Banner" />
              </div>
              <h2>{serviceDetails.sname} Overview</h2>
              <p>{serviceDetails.sdetails}</p>
              <p>{serviceDetails.mdetails}</p>
            </div>

            <div className="col-lg-4 sidebar">
              <div className="sidebar-item link">
                <ul>
                  {services.map((service) => (
                    <li key={service._id}>
                      <Link to={`/service/${service._id}`}>
                        {service.sname}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="sidebar-item brochure">
                <div className="title">
                  <h4>Brochure</h4>
                </div>
                <p>Get a hand printable format</p>
                <a href="#">
                  <i className="fas fa-file-pdf"></i> Download Service
                </a>
                <a href="#">
                  <i className="fas fa-file-archive"></i> Download Features
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Services Details */}
    </>
  );
};

export default ServiceDetails;
