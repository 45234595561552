import React, { useState, useEffect } from "react";
import axios from "axios";

const Team = () => {
  const [formData, setFormData] = useState({
    companyname: "",
    intro: "",
    logo: "",
    contact: "",
    email: "",
    address: "",
    facebook: "",
    youtube: "",
    instagram: "",
    tiktok: "",
    linkedin: "",
  });

  const [aboutData, setAboutData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState(null);
  const [showForm, setShowForm] = useState(false); // New state for form visibility

  useEffect(() => {
    fetchAboutData();
  }, []);

  const fetchAboutData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_BACK}/api/settings`
      );
      setAboutData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataToSend = new FormData();
    for (const key in formData) {
      if (formData[key] instanceof File) {
        dataToSend.append(key, formData[key]);
      } else {
        dataToSend.append(key, formData[key]);
      }
    }
    try {
      if (editMode) {
        await axios.put(
          `${process.env.REACT_APP_API_URL_BACK}/api/settings/${editId}`,
          dataToSend
        );
      } else {
        await axios.post(
          `${process.env.REACT_APP_API_URL_BACK}/api/settings`,
          dataToSend
        );
      }
      setFormData({
        companyname: "",
        intro: "",
        logo: "",
        contact: "",
        email: "",
        address: "",
        facebook: "",
        youtube: "",
        instagram: "",
        tiktok: "",
        linkedin: "",
      });
      setEditMode(false);
      setShowForm(false); // Hide form after submission
      fetchAboutData();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleEdit = (data) => {
    setFormData({
      companyname: data.companyname,
      intro: data.intro,
      logo: data.logo,
      contact: data.contact,
      email: data.email,
      address: data.address,
      facebook: data.facebook,
      youtube: data.youtube,
      instagram: data.instagram,
      tiktok: data.tiktok,
      linkedin: data.linkedin,
    });
    setEditId(data._id);
    setEditMode(true);
    setShowForm(true); // Show form in edit mode
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL_BACK}/api/settings/${id}`
      );
      fetchAboutData();
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  return (
    <div className="container mt-5">
      <h2>Settings Section</h2>
      <button
        className="btn btn-primary mb-3"
        onClick={() => {
          setShowForm(!showForm); // Toggle form visibility
          setEditMode(false); // Reset edit mode when adding new data
        }}>
        {showForm ? "Cancel" : "Add Data"}
      </button>
      {showForm && (
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="companyname">Company Name</label>
              <input
                type="text"
                className="form-control"
                id="companyname"
                name="companyname"
                value={formData.companyname}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group col-md-6">
              <label htmlFor="intro">Introduction</label>
              <input
                type="text"
                className="form-control"
                id="intro"
                name="intro"
                value={formData.intro}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-4">
              <label htmlFor="contact">Contact</label>
              <input
                type="text"
                className="form-control"
                id="contact"
                name="contact"
                value={formData.contact}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group col-md-4">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="address">Address</label>
              <input
                type="text"
                className="form-control"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-4">
              <label htmlFor="facebook">Facebook</label>
              <input
                type="text"
                className="form-control"
                id="facebook"
                name="facebook"
                value={formData.facebook}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group col-md-4">
              <label htmlFor="youtube">Youtube</label>
              <input
                type="text"
                className="form-control"
                id="youtube"
                name="youtube"
                value={formData.youtube}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="instagram">Instagram</label>
              <input
                type="text"
                className="form-control"
                id="instagram"
                name="instagram"
                value={formData.instagram}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-4">
              <label htmlFor="tiktok">Tiktok</label>
              <input
                type="text"
                className="form-control"
                id="tiktok"
                name="tiktok"
                value={formData.tiktok}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group col-md-4">
              <label htmlFor="linkedin">Linkedin</label>
              <input
                type="text"
                className="form-control"
                id="linkedin"
                name="linkedin"
                value={formData.linkedin}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="logo">Logo</label>
              <input
                type="file"
                className="form-control"
                id="logo"
                name="logo"
                onChange={handleFileChange}
              />
              <img
                src={
                  formData.logo
                    ? `${process.env.REACT_APP_API_URL_BACK}/${formData.logo}`
                    : "default-image-path.jpg"
                }
                alt="Logo"
                style={{ width: "150px", height: "50px", objectFit: "cover" }}
              />
            </div>
          </div>

          <button type="submit" className="btn btn-primary">
            {editMode ? "Update" : "Add"}
          </button>
        </form>
      )}
      <table className="table">
        <thead>
          <tr>
            <th>Logo</th>
            <th>Name</th>
            <th>Contact</th>
            <th>Email</th>
            <th>Address</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {aboutData.map((data) => (
            <tr key={data._id}>
              <td>
                <img
                  src={`${process.env.REACT_APP_API_URL_BACK}/${data.logo}`}
                  alt="Image"
                  width="100"
                />
              </td>
              <td>{data.companyname}</td>
              <td>{data.contact}</td>
              <td>{data.email}</td>
              <td>{data.address}</td>
              <td>
                <button onClick={() => handleEdit(data)}>
                  <i className="fas fa-pen"></i>
                </button>
                <button
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to delete this item?"
                      )
                    ) {
                      handleDelete(data._id);
                    }
                  }}>
                  <i className="fas fa-trash"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Team;
