import React from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Remove the JWT token from localStorage
    localStorage.removeItem("token");

    // Optionally, you could send a request to the server to handle server-side logout (if applicable)
    // For example: await axios.post('/api/auth/logout');

    // Redirect to login page
    navigate("/login");
  };

  return (
    <button onClick={handleLogout} className="btn btn-outline-danger">
      Logout
    </button>
  );
};

export default Logout;
