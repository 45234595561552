import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import bannerImg from "../../assets/img/banner/15.jpg";

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL_BACK}/api/blog`
        );
        setBlogs(response.data);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      {/* Start Breadcrumb */}
      <div
        className="breadcrumb-area shadow dark bg-fixed text-light"
        style={{ backgroundImage: `url(${bannerImg})` }}>
        <div className="container">
          <div className="row align-center">
            <div className="col-lg-6">
              <h2>Blogs</h2>
            </div>
            <div className="col-lg-6 text-right">
              <ul className="breadcrumb">
                <li>
                  <Link to="/">
                    <i className="fas fa-home"></i> Home
                  </Link>
                </li>
                <li>
                  <Link to="#">Pages</Link>
                </li>
                <li className="active">Blogs</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrumb */}

      {/* Start Blog */}
      <div className="blog-area full-blog blog-standard full-blog grid-colum default-padding">
        <div className="container">
          <div className="blog-items">
            <div className="blog-content">
              <div className="blog-item-box">
                <div className="row">
                  {/* Single Item */}
                  {blogs.map((blog, index) => (
                    <div key={index} className="col-lg-4 col-md-6 single-item">
                      <div className="item wow fadeInUp" data-wow-delay="500ms">
                        <div className="thumb">
                          <Link to="blog-single-with-sidebar.html">
                            <img src={blog.image} alt="Thumb" />
                          </Link>
                        </div>
                        <div className="info">
                          <div className="cats">
                            <Link to="#">{blog.category}</Link>
                          </div>
                          <div className="meta">
                            <ul>
                              <li>
                                <i className="fas fa-calendar-alt"></i>{" "}
                                {formatDate(blog.date)}
                              </li>
                              <li>
                                By <Link to="#">{blog.author}</Link>
                              </li>
                            </ul>
                          </div>
                          <h4>
                            <Link to={`/blog/${blog._id}`}>{blog.title}</Link>
                          </h4>
                          <p>
                            {blog.paragraph_one
                              .split(" ")
                              .slice(0, 20)
                              .join(" ") +
                              (blog.paragraph_one.split(" ").length > 20
                                ? "..."
                                : "")}
                          </p>

                          <Link
                            className="btn circle btn-theme effect btn-md"
                            to={`/blog/${blog._id}`}>
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* End Single Item */}
                </div>

                {/* Pagination */}
                <div className="row">
                  <div className="col-12 text-center">
                    <div className="pagination">
                      <ul>
                        <li className="prev">
                          <Link to="#">
                            <i className="fas fa-long-arrow-alt-left"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">1</Link>
                        </li>
                        <li>
                          <Link to="#">2</Link>
                        </li>
                        <li className="active">
                          <Link to="#">3</Link>
                        </li>
                        <li>
                          <Link to="#">4</Link>
                        </li>
                        <li>
                          <Link to="#">...</Link>
                        </li>
                        <li>
                          <Link to="#">10</Link>
                        </li>
                        <li className="next">
                          <Link to="#">
                            <i className="fas fa-long-arrow-alt-right"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Blogs */}
    </>
  );
};

export default Blogs;
