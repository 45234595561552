import React, { useEffect, useState } from "react";
import axios from "axios";

const ProtectedPage = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get("http://localhost:5000/protected", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setData(response.data);
      } catch (error) {
        setError("Failed to fetch data.");
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {error && <p>{error}</p>}
      {data ? <div>{data}</div> : <p>Loading...</p>}
    </div>
  );
};

export default ProtectedPage;
