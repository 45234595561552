import React from "react";

const Footer = () => {
  return (
    <footer className="footer bg-dark text-white text-center py-3 shadow-sm">
      <div className="container">
        <p className="mb-0">&copy; 2024 Tech Vision IT Solution</p>
      </div>
    </footer>
  );
};

export default Footer;
