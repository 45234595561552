import React, { useState, useEffect } from "react";
import axios from "axios";

const Blog = () => {
  const [formData, setFormData] = useState({
    title: "",
    date: "",
    author: "",
    category: "",
    paragraph_one: "",
    paragraph_two: "",
    tags: "",
    blog_link: "",
    image: "",
  });

  const [aboutData, setAboutData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState(null);
  const [showForm, setShowForm] = useState(false); // New state for form visibility

  useEffect(() => {
    fetchAboutData();
  }, []);

  const fetchAboutData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_BACK}/api/blog`
      );
      setAboutData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataToSend = new FormData();
    for (const key in formData) {
      if (formData[key] instanceof File) {
        dataToSend.append(key, formData[key]);
      } else {
        dataToSend.append(key, formData[key]);
      }
    }
    try {
      if (editMode) {
        await axios.put(
          `${process.env.REACT_APP_API_URL_BACK}/api/blog/${editId}`,
          dataToSend
        );
      } else {
        await axios.post(
          `${process.env.REACT_APP_API_URL_BACK}/api/blog`,
          dataToSend
        );
      }
      setFormData({
        title: "",
        date: "",
        author: "",
        category: "",
        paragraph_one: "",
        paragraph_two: "",
        tags: "",
        blog_link: "",
        image: "",
      });
      setEditMode(false);
      setShowForm(false); // Hide form after submission
      fetchAboutData();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleEdit = (data) => {
    setFormData({
      title: data.title,
      date: data.date,
      author: data.author,
      category: data.category,
      paragraph_one: data.paragraph_one,
      paragraph_two: data.paragraph_two,
      tags: data.tags,
      blog_link: data.blog_link,
      image: data.image,
    });
    setEditId(data._id);
    setEditMode(true);
    setShowForm(true); // Show form in edit mode
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL_BACK}/api/blog/${id}`
      );
      fetchAboutData();
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  return (
    <div className="container mt-5">
      <h2>Blog Section</h2>
      <button
        className="btn btn-primary mb-3"
        onClick={() => {
          setShowForm(!showForm); // Toggle form visibility
          setEditMode(false); // Reset edit mode when adding new data
        }}>
        {showForm ? "Cancel" : "Add Data"}
      </button>
      {showForm && (
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="form-row">
            <div className="form-group col-md-12">
              <label htmlFor="title">Title</label>
              <input
                type="text"
                className="form-control"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-4">
              <label htmlFor="date">Date</label>
              <input
                type="date"
                className="form-control"
                id="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="author">Author Name</label>
              <input
                type="text"
                className="form-control"
                id="author"
                name="author"
                value={formData.author}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="category">Category</label>
              <input
                type="text"
                className="form-control"
                id="category"
                name="category"
                value={formData.category}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-12">
              <label htmlFor="paragraph_one">Paragraph One</label>
              <input
                type="text"
                className="form-control"
                id="paragraph_one"
                name="paragraph_one"
                value={formData.paragraph_one}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-12">
              <label htmlFor="paragraph_two">Paragraph Two</label>
              <input
                type="text"
                className="form-control"
                id="paragraph_two"
                name="paragraph_two"
                value={formData.paragraph_two}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-4">
              <label htmlFor="tags">Tags</label>
              <input
                type="text"
                className="form-control"
                id="tags"
                name="tags"
                value={formData.tags}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="blog_link">Next Blog Link</label>
              <input
                type="text"
                className="form-control"
                id="blog_link"
                name="blog_link"
                value={formData.blog_link}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="image">Cover Image</label>
              <input
                type="file"
                className="form-control"
                id="image"
                name="image"
                onChange={handleFileChange}
              />
              <img
                src={
                  formData.image
                    ? `${process.env.REACT_APP_API_URL_BACK}/${formData.image}`
                    : "default-image-path.jpg"
                }
                alt="Cover Image"
                style={{ width: "150px", height: "50px", objectFit: "image" }}
              />
            </div>
          </div>

          <button type="submit" className="btn btn-primary">
            {editMode ? "Update" : "Add"}
          </button>
        </form>
      )}
      <table className="table">
        <thead>
          <tr>
            <th>Image</th>
            <th>Title</th>
            <th>Date</th>
            <th>Author</th>
            <th>Category</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {aboutData.map((data) => (
            <tr key={data._id}>
              <td>
                <img
                  src={`${process.env.REACT_APP_API_URL_BACK}/${data.image}`}
                  alt="image"
                  width="100"
                />
              </td>
              <td>{data.title}</td>
              <td>{data.date}</td>
              <td>{data.author}</td>
              <td>{data.category}</td>
              <td>
                <button onClick={() => handleEdit(data)}>
                  <i className="fas fa-pen"></i>
                </button>
                <button
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to delete this item?"
                      )
                    ) {
                      handleDelete(data._id);
                    }
                  }}>
                  <i className="fas fa-trash"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Blog;
