import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import bannerImage from "../../assets/img/banner/15.jpg";

const BlogDetails = () => {
  const [blogs, setBlogs] = useState([]);
  const [blogDetails, setBlogDetails] = useState(null);
  const { id } = useParams(); // Get the Blog id from the URL

  useEffect(() => {
    const fetchblogDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL_BACK}/api/blog/${id}`
        );
        setBlogDetails(response.data);
      } catch (error) {
        console.error("Error fetching Blog details", error);
      }
    };

    fetchblogDetails();
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL_BACK}/api/blog`
        );
        setBlogs(response.data);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);

  // Conditionally render content only if Blog is available
  if (!blogDetails) {
    return <div>Loading...</div>; // You can show a loader or message here
  }

  const CoverURL =
    blogDetails && blogDetails.image
      ? `${process.env.REACT_APP_API_URL_BACK}/${blogDetails.image}`
      : Image; // Fallback image if `cover` is not available

  const tagsArray = blogDetails?.tags ? blogDetails.tags.split(",") : [];
  const categoryArray = blogDetails?.category
    ? blogDetails.category.split(",")
    : [];
  return (
    <>
      {/* Start Breadcrumb */}
      <div
        className="breadcrumb-area shadow dark bg-fixed text-light"
        style={{ backgroundImage: `url(${bannerImage})` }}>
        <div className="container">
          <div className="row align-center">
            <div className="col-lg-6">
              <h2>{blogDetails.title}</h2>
            </div>
            <div className="col-lg-6 text-right">
              <ul className="breadcrumb">
                <li>
                  <Link to="/">
                    <i className="fas fa-home"></i> Home
                  </Link>
                </li>
                <li>
                  <Link to="#">Pages</Link>
                </li>
                <li className="active">Blogs Details</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrumb */}

      {/* Start Blog Details */}
      <div className="blog-area single full-blog right-sidebar full-blog default-padding">
        <div className="container">
          <div className="blog-items">
            <div className="row">
              {/* Blog Content */}
              <div className="blog-content wow fadeInUp col-lg-8 col-md-12">
                <div className="item">
                  <div className="blog-item-box">
                    {/* Start Post Thumb */}
                    <div className="thumb">
                      <img src={CoverURL} alt="Thumb" />
                    </div>
                    {/* End Post Thumb */}

                    <div className="info">
                      <div className="cats">
                        <a href="#">{blogDetails.category}</a>
                      </div>
                      <div className="meta">
                        <ul>
                          <li>
                            <i className="fas fa-calendar-alt"></i>{" "}
                            {blogDetails.date}
                          </li>
                          <li>
                            By <a href="#">{blogDetails.author}</a>
                          </li>
                        </ul>
                      </div>

                      <h3>{blogDetails.title}</h3>
                      <p style={{ textAlign: "justify" }}>
                        {blogDetails.paragraph_one}
                      </p>
                      <p style={{ textAlign: "justify" }}>
                        {blogDetails.paragraph_two}
                      </p>
                    </div>
                  </div>
                </div>

                {/* Post Pagination */}
                {/* <div className="post-pagi-area">
                  <a href="#">
                    <i class="fas fa-angle-double-left"></i> Previous Post
                    <h5>Hello World</h5>
                  </a>
                  <a href="#">
                    Next Post <i class="fas fa-angle-double-right"></i>
                    <h5>The Earth Brown</h5>
                  </a>
                </div> */}
                {/* End Post Pagination */}

                {/* Post Tags */}
                <div className="post-tags share">
                  <div className="tags">
                    {tagsArray.map((tags, index) => (
                      <a key={index}>{tags}</a>
                    ))}
                  </div>
                </div>
                {/* End Post Tags */}
              </div>

              {/* Sidebar */}
              <div className="sidebar wow fadeInLeft col-lg-4 col-md-12">
                <aside>
                  {/* Search */}
                  <div className="sidebar-item search">
                    <div className="sidebar-info">
                      <form>
                        <input
                          type="text"
                          name="text"
                          className="form-control"
                        />
                        <button type="submit">
                          <i className="fas fa-search"></i>
                        </button>
                      </form>
                    </div>
                  </div>

                  {/* Recent Post */}
                  <div className="sidebar-item recent-post">
                    <div className="title">
                      <h4>Recent Post</h4>
                    </div>
                    <ul>
                      {blogs.map((blog, index) => (
                        <li key={index}>
                          <div className="thumb">
                            <a href="#">
                              <img src={CoverURL} alt="Thumb" />
                            </a>
                          </div>
                          <div className="info">
                            <a href="#">{blog.title}</a>
                            <div className="meta-title">
                              <span className="post-date">
                                <i className="fas fa-clock"></i> {blog.date}
                              </span>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>

                  {/* Category List */}
                  <div className="sidebar-item category">
                    <div className="title">
                      <h4>Category List</h4>
                    </div>
                    <div className="sidebar-info">
                      <ul>
                        {categoryArray.map((category, index) => (
                          <li key={index}>
                            <a>{category} (3)</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  {/* Tags */}
                  <div className="sidebar-item tags">
                    <div className="title">
                      <h4>Tags</h4>
                    </div>
                    <div className="sidebar-info">
                      <ul>
                        {tagsArray.map((tags, index) => (
                          <li key={index}>
                            <a href="#">{tags}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </aside>
              </div>
              {/* End Sidebar */}
            </div>
          </div>
        </div>
      </div>
      {/* End Blogs Details */}
    </>
  );
};

export default BlogDetails;
