import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

// Simple Authentication Check with Warning
const ProtectedRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem("token");

  useEffect(() => {
    if (!isAuthenticated) {
      alert("You need to log in to access this page.");
    }
  }, [isAuthenticated]);

  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
