import React, { useState, useEffect } from "react";
import axios from "axios";

const Service = () => {
  const [formData, setFormData] = useState({
    sname: "",
    sdetails: "",
    icon: "",
    cover: "",
    mdetails: "",
  });

  const [aboutData, setAboutData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState(null);
  const [showForm, setShowForm] = useState(false); // New state for form visibility

  useEffect(() => {
    fetchAboutData();
  }, []);

  const fetchAboutData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_BACK}/api/service`
      );
      setAboutData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataToSend = new FormData();
    for (const key in formData) {
      if (formData[key] instanceof File) {
        dataToSend.append(key, formData[key]);
      } else {
        dataToSend.append(key, formData[key]);
      }
    }
    try {
      if (editMode) {
        await axios.put(
          `${process.env.REACT_APP_API_URL_BACK}/api/service/${editId}`,
          dataToSend
        );
      } else {
        await axios.post(
          `${process.env.REACT_APP_API_URL_BACK}/api/service`,
          dataToSend
        );
      }
      setFormData({
        sname: "",
        sdetails: "",
        icon: "",
        cover: "",
        mdetails: "",
      });
      setEditMode(false);
      setShowForm(false); // Hide form after submission
      fetchAboutData();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleEdit = (data) => {
    setFormData({
      sname: data.sname,
      sdetails: data.sdetails,
      icon: data.icon,
      cover: data.cover,
      mdetails: data.mdetails,
    });
    setEditId(data._id);
    setEditMode(true);
    setShowForm(true); // Show form in edit mode
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL_BACK}/api/service/${id}`
      );
      fetchAboutData();
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  return (
    <div className="container mt-5">
      <h2>Service Section</h2>
      <button
        className="btn btn-primary mb-3"
        onClick={() => {
          setShowForm(!showForm); // Toggle form visibility
          setEditMode(false); // Reset edit mode when adding new data
        }}>
        {showForm ? "Cancel" : "Add Data"}
      </button>
      {showForm && (
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="sname">Service Name</label>
              <input
                type="text"
                className="form-control"
                id="sname"
                name="sname"
                value={formData.sname}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group col-md-9">
              <label htmlFor="sdetails">Service Details</label>
              <input
                type="text"
                className="form-control"
                id="sdetails"
                name="sdetails"
                value={formData.sdetails}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="icon">Icon</label>
              <input
                type="text"
                className="form-control"
                id="icon"
                name="icon"
                value={formData.icon}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group col-md-9">
              <label htmlFor="mdetails">More Details</label>
              <input
                type="text"
                className="form-control"
                id="mdetails"
                name="mdetails"
                value={formData.mdetails}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="cover">Cover</label>
              <input
                type="file"
                className="form-control"
                id="cover"
                name="cover"
                onChange={handleFileChange}
              />
              <img
                src={
                  formData.cover
                    ? `${process.env.REACT_APP_API_URL_BACK}/${formData.cover}`
                    : "default-image-path.jpg"
                }
                alt="Cover"
                style={{ width: "150px", height: "50px", objectFit: "cover" }}
              />
            </div>
          </div>

          <button type="submit" className="btn btn-primary">
            {editMode ? "Update" : "Add"}
          </button>
        </form>
      )}
      <table className="table">
        <thead>
          <tr>
            <th>Image</th>
            <th>Service Name</th>
            <th>Service Details</th>
            <th>Icon</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {aboutData.map((data) => (
            <tr key={data._id}>
              <td>
                <img
                  src={`${process.env.REACT_APP_API_URL_BACK}/${data.cover}`}
                  alt="Cover"
                  width="100"
                />
              </td>
              <td>{data.sname}</td>
              <td>{data.sdetails}</td>
              <td>{data.icon}</td>
              <td>
                <button onClick={() => handleEdit(data)}>
                  <i className="fas fa-pen"></i>
                </button>
                <button
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to delete this item?"
                      )
                    ) {
                      handleDelete(data._id);
                    }
                  }}>
                  <i className="fas fa-trash"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Service;
