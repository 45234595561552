import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import logoLight from "../../assets/img/logo-light.png";

import "../../assets/js/jquery-3.3.1.min.js";
import "../../assets/js/bootstrap.min.js";
import "../../assets/js/popper.min.js";
import "../../assets/js/equal-height.min.js";
import "../../assets/js/jquery.appear.js";
import "../../assets/js/jquery.easing.min.js";
import "../../assets/js/jquery.magnific-popup.min.js";
import "../../assets/js/owl.carousel.min.js";
import "../../assets/js/wow.min.js";
import "../../assets/js/progress-bar.min.js";
import "../../assets/js/isotope.pkgd.min.js";
import "../../assets/js/imagesloaded.pkgd.min.js";
import "../../assets/js/count-to.js";
import "../../assets/js/YTPlayer.min.js";
import "../../assets/js/jquery.nice-select.min.js";
import "../../assets/js/bootsnav.js";
import "../../assets/js/main.js";

import Shape1 from "../../assets/img/map.svg";

const Footer = () => {
  // State to store the settings (logo, address, email, year, designedBy)
  const [services, setServices] = useState([]);
  const [settings, setSettings] = useState({
    logo: "",
    address: "",
    email: "",
    year: new Date().getFullYear(), // Dynamically set the current year by default
    companyname: "", // Default designer name if not fetched from API
  });

  // Fetch settings from API
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL_BACK}/api/settings`
        );
        if (response.data.length > 0) {
          setSettings(response.data[0]); // Assuming you're fetching a single settings object
        }
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };

    fetchSettings();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL_BACK}/api/service`
        );
        setServices(response.data);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);

  const LogoURL =
    settings && settings.logo
      ? `${process.env.REACT_APP_API_URL_BACK}/${settings.logo}`
      : logoLight;

  return (
    <footer className="bg-dark text-light">
      {/* Fixed Shape */}
      <div className="fixed-shape">
        <img src={Shape1} alt="Shape" />
      </div>

      <div className="container">
        <div className="f-items default-padding">
          <div className="row">
            <div className="col-lg-4 col-md-6 item">
              <div className="f-item about">
                {/* Dynamically use the logo */}
                <img src={LogoURL} alt="Logo" style={{ height: "65px" }} />
                <p>
                  Empowering Businesses with Innovative IT Solutions and Digital
                  Growth.
                </p>
                <div className="top-bar-area inc-pad  text-light">
                  <div className="social">
                    <ul>
                      <li>
                        <a href={settings.facebook}>
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href={settings.youtube}>
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                      <li>
                        <a href={settings.linkedin}>
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 item">
              <div className="f-item link">
                <h4 className="widget-title">Company</h4>
                <ul>
                  <li>
                    <a href="/about">About Us</a>
                  </li>
                  <li>
                    <a href="/team">Meet Our Team</a>
                  </li>
                  <li>
                    <a href="/works">Works</a>
                  </li>
                  <li>
                    <a href="/blogs">Blogs</a>
                  </li>
                  <li>
                    <a href="/contact">Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 item">
              <div className="f-item link">
                <h4 className="widget-title">Solutions</h4>
                <ul>
                  {services.map((service) => (
                    <li key={service._id}>
                      <Link to={`/service/${service._id}`}>
                        {service.sname}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 item">
              <div className="f-item">
                <h4 className="widget-title">Contact Info</h4>
                <div className="address">
                  <ul>
                    <li>
                      {/* Dynamically use the address */}
                      <strong>Address:</strong> {settings.address}
                    </li>
                    <li>
                      {/* Dynamically use the email */}
                      <strong>Email:</strong>
                      <a href={`mailto:${settings.email}`}>{settings.email}</a>
                    </li>
                  </ul>
                </div>

                <div className="download">
                  <h5>Download</h5>
                  <a href="#">
                    <i className="fab fa-apple"></i> App Store
                  </a>
                  <a href="#">
                    <i className="fab fa-google-play"></i> Google Play
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <p>
                {/* Dynamically use the year and designedBy */}
                Copyright &copy; 2024 | Designed by{" "}
                <a href="#">{settings.companyname}</a>
              </p>
            </div>
            <div className="col-md-6 text-right link">
              <ul>
                <li>
                  <a href="#">Terms</a>
                </li>
                <li>
                  <a href="#">Privacy</a>
                </li>
                <li>
                  <a href="#">Support</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
