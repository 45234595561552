import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "../../Css/Sidebar.css"; // Ensure you have styles for the sidebar

const Sidebar = () => {
  const [isCompanyOpen, setIsCompanyOpen] = useState(false); // Track dropdown state
  const [isLPOpen, setIsLPOpen] = useState(false); // Track dropdown state
  // Toggle company dropdown
  const toggleCompanyMenu = () => setIsCompanyOpen(!isCompanyOpen);

  // Toggle Landing Page Section dropdown
  const toggleLPMenu = () => setIsLPOpen(!isLPOpen);

  // Reusable function for NavLink styles
  const getNavLinkStyle = ({ isActive }) => ({
    fontWeight: isActive ? "bold" : "normal",
    backgroundColor: isActive ? "#495057" : "",
  });

  // Define main menu items and submenus
  const menuItems = [
    { name: "Service", path: "/admin/service" },
    { name: "Work", path: "/admin/work" },
    { name: "Blog", path: "/admin/blog" },
    { name: "Message", path: "/admin/contact" },
    { name: "User Management", path: "/admin/user-management" },
    { name: "Settings", path: "/admin/settings" },
  ];

  const companySubMenu = [
    { name: "About Company", path: "/admin/aboutcompany" },
    { name: "Why Choose Us", path: "/admin/whychooseus" },
    { name: "Team", path: "/admin/team" },
  ];

  const LandingPageSection = [{ name: "Slider", path: "/admin/slider" }];

  return (
    <aside className="sidebar bg-dark text-white shadow-sm">
      <div className="sidebar-content">
        <ul className="nav flex-column">
          <li className="nav-item">
            <NavLink
              className="nav-link text-white d-flex justify-content-between align-items-center"
              to="/dashboard"
              style={getNavLinkStyle}>
              Dashboard
              <i className="fas fa-chevron-right"></i>
            </NavLink>
          </li>

          {/* Company with Submenu */}
          <li className="nav-item">
            <div
              className="nav-link text-white d-flex justify-content-between align-items-center"
              onClick={toggleCompanyMenu}
              style={{ cursor: "pointer" }}>
              Company
              <i
                className={`fas ${
                  isCompanyOpen ? "fa-chevron-down" : "fa-chevron-right"
                }`}></i>
            </div>

            {/* Submenu */}
            <ul className={`collapse ${isCompanyOpen ? "show" : ""}`}>
              {companySubMenu.map((subItem) => (
                <li className="nav-item" key={subItem.path}>
                  <NavLink
                    className="nav-link text-white"
                    to={subItem.path}
                    style={getNavLinkStyle}>
                    {subItem.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </li>

          {menuItems.map((item) => (
            <li className="nav-item" key={item.path}>
              <NavLink
                className="nav-link text-white d-flex justify-content-between align-items-center"
                to={item.path}
                style={getNavLinkStyle}>
                {item.name}
                <i className="fas fa-chevron-right"></i>
              </NavLink>
            </li>
          ))}

          {/* LandingPageSection with Submenu */}
          <li className="nav-item">
            <div
              className="nav-link text-white d-flex justify-content-between align-items-center"
              onClick={toggleLPMenu}
              style={{ cursor: "pointer" }}>
              Landing Page
              <i
                className={`fas ${
                  isLPOpen ? "fa-chevron-down" : "fa-chevron-right"
                }`}></i>
            </div>

            {/* Submenu */}
            <ul className={`collapse ${isLPOpen ? "show" : ""}`}>
              {LandingPageSection.map((subItem) => (
                <li className="nav-item" key={subItem.path}>
                  <NavLink
                    className="nav-link text-white"
                    to={subItem.path}
                    style={getNavLinkStyle}>
                    {subItem.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </li>
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;
