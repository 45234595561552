import React from "react";
import Navbar from "../partial/backend/Navbar"; // Import your Navbar component
import Sidebar from "../partial/backend/Sidebar"; // Import your Sidebar component
import Footer from "../partial/backend/Footer"; // Import your Footer component
import "../Css/BackendLayout.css"; // Add necessary layout styles

const BackendLayout = ({ children }) => {
  return (
    <div className="backend-layout">
      <Navbar />
      <div className="d-flex">
        <Sidebar />
        <div className="content-container flex-grow-1">
          <div className="content">{children}</div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BackendLayout;
